<template>
  <div class="main">
    <div class="article_head kefan">
      <div class="anli_head">
        <el-image
        style="width: 30rem; height: 12rem"
        :src="anlibgc"
        :fit="'contain'">
        </el-image>
      </div>
      <div class="search_tools">
        <el-input
          placeholder="请输标题关键字"
          prefix-icon="el-icon-search"
          v-model="anliname"
        >
        </el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="searchanli(anliname)"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="article_data kefan">
      <el-table
        :data="tableData"
        ref="multipleTable"
        tooltip-effect="dark"
        stripe
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column prop="id" label="id" width="90"> </el-table-column>
        <el-table-column prop="title" label="案例标题" width="260">
        </el-table-column>
        <el-table-column
          prop="fenlei"
          label="所属分类"
          :filters="fenleiList"
          :filter-method="filterTag"
          filter-placement="bottom-end"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="bumen"
          label="所属部门"
          :filters="bumenList"
          :filter-method="filterbumen"
          filter-placement="bottom-end"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="yuanqu"
          label="所属园区"
          :filters="yuanquList"
          :filter-method="filteryuanqu"
          filter-placement="bottom-end"
          width="130"
        >
        </el-table-column>
        <el-table-column prop="author" label="作者" width="100">
        </el-table-column>
        <el-table-column prop="date" label="发表日期" width="180">
        </el-table-column>
        <el-table-column prop="view" label="浏览数" width="80">
        </el-table-column>
        <el-table-column prop="good" label="点赞数" width="80">
        </el-table-column>
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="routeto(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pag">
        <span class="demonstration"></span>
        <el-pagination
          background
          layout="total,prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pagesize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      dialogFormVisible: false,
      formLabelWidth: "150px",
      pagesize: 6,
      currentPage: 1,
      total: 10,
      anlibgc:this.$img+"anli_bgc2.png",
      fenleiList: [],
      yuanquList: [],
      bumenList: [],
      anlinums: [],
      options: [],
      yuanquoptions: [],
      bumenoptions: [],
      anliname: "",
    };
  },
  mounted() {
    this.getanli(this.anliname);
    this.getyuanqubumen();
    this.getanlifenlei();
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 筛选方法
    filterTag(value, row) {
      return row.fenlei === value;
    },
    filterbumen(value, row) {
      return row.bumen === value;
    },
    filteryuanqu(value, row) {
      return row.yuanqu === value;
    },
    // 获取园区部门列表
    getyuanqubumen() {
      axios({
        method: "get",
        url: this.$host + this.$api.allyuanqubumeng,
      }).then((res) => {
        this.yuanquoptions = res.data.yuanqu;
        this.bumenoptions = res.data.bumen;
      });
    },
    // 关键字查询
    searchanli(value) {
      if (value !== "") {
        this.getanli(value);
      }
    },
    routeto(id) {
      this.$router.push({ path: "/article", query: { id: id } });
    },
    // 获取案例分类列表
    getanlifenlei() {
      axios({
        method: "get",
        url: this.$host + this.$api.anlifenlei,
      }).then((res) => {
        this.options = res.data;
      });
    },
    // 获取案例
    getanli(val) {
      axios({
        method: "post",
        url: this.$host + this.$api.anli,
        data: {
          name: val,
          currentPage: this.currentPage,
          pagesize: this.pagesize,
        },
      }).then((res) => {
        // console.log(res.data)
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.initdata();
      });
    },
    // 分页处理
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getanli(this.anliname);
    },

    // 初始化数据
    initdata() {
      // 获取分类数据
      this.fenleiList = [];
      this.yuanquList = [];
      this.bumenList = [];
      for (let index = 0; index < this.options.length; index++) {
        var a = {
          text: this.options[index].label,
          value: this.options[index].label,
        };
        this.fenleiList.push(a);
      }
      // 获取园区数据
      for (let index = 0; index < this.yuanquoptions.length; index++) {
        var b = {
          text: this.yuanquoptions[index].label,
          value: this.yuanquoptions[index].label,
        };
        this.yuanquList.push(b);
      }
      // 获取部门数据
      for (let index = 0; index < this.bumenoptions.length; index++) {
        var c = {
          text: this.bumenoptions[index].label,
          value: this.bumenoptions[index].label,
        };
        this.bumenList.push(c);
      }
    },
  },
};
</script>

<style scoped>
.sx {
  display: flex;
  align-items: center;
}
.pag {
  display: flex;
  align-items: center;
  justify-content: right;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.article_head .search_tools{
  display: flex;
  align-items: center;
  justify-content: center;
}
.anli_head{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>